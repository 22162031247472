.container-fluid {
  margin: auto;
}

.container-principal {
  margin: auto;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 20%);
  width: 1000px;
  border-radius: 13px;
  padding: 2rem;
}

.lbl_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem;
}
