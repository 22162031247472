@import url("https://fonts.googleapis.com/css2?family=Monda&display=swap");

.titulo {
  width: 100%;
  background-color: transparent;
  font-family: "Monda", sans-serif;
  color: white;
  font-size: 60px;
  text-align: left;
  margin-left: 14%;
}

.principal {
  background-color: #27262c;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
  display: flex;
}

.loginiput {
  font-family: "Monda", sans-serif;
  width: 70%;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: transparent;
  border-bottom: 1px solid white;
  border-top: 0px transparent;
  border-right: 0px transparent;
  border-left: 0px transparent;
  color: white;
  padding: 1rem 10px 5px;
}

.loginiput:active {
  background-color: rgb(167, 167, 167);
}

label {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
  gap: 10px;
  color: white;
  font-size: 12px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #c5c3cb inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-login {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.btnlogin {
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  font-size: 15px;
  width: 75%;
  background-color: rgb(17, 17, 170);
}

.btnlogin:hover {
  background-color: rgb(254, 80, 0);
}

.logobanner {
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin: auto;
  background-color: transparent;
  width: 100px;
  height: 45px;
  border-radius: 1px;
}

.container-login p {
  color: lightgray;
  margin-top: 10px;
  font-size: 16px;
}

.formulario {
  padding: 10%;
}

.addimg {
  width: 10%;
  height: 20%;
  border-radius: 0%;
  margin: 0% 0% 0% 15%;
  background-color: transparent;
  cursor: pointer;
}

.log {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 5px;
}
