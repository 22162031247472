* {
  margin: 0px;
  padding: 0px;
}

.home {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  border: 0px solid white;
  border-radius: 10px;
  width: 75%;
  height: 75%;
  display: flex;
  overflow: hidden;
  padding: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.sidebar {
  flex: 1;
  height: 100%;
  width: 100%;
  border-right: 1px solid #3e3c61;
  background-color: #3e3c61;
}

.chat {
  flex: 2;
}

.navbarchat {
  display: flex;
  align-items: center;
  background-color: #2f2d52;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  color: #ddddf7;
}

.logo2 {
  font-weight: bold;
}

.user {
  display: flex;
  gap: 10px;
}

img {
  background-color: #ddddf7;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.imgChats {
  background-color: #ddddf7;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

button {
  background-color: #5d5b8d;
  color: #ddddf7;
  font-size: 10px;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.search {
  border-bottom: 1px solid gray;
  padding: 10px;
}

.searchForm {
  padding: 10px;
}

.inputSearch {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  font-size: 10px;
}

.userChat {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  cursor: pointer;
}

.userChat:hover {
  background-color: #2f2d52;
}

.userChatInfo span {
  font-size: 18px;
  font-weight: 500;
}

.userChatInfo p {
  font-size: 12px;
  color: lightgray;
}

.chatInfo {
  height: 50px;
  background-color: #5d5b8d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: lightgray;
}

.messages {
  background-color: #ddddf7;
  padding: 10px;
  height: calc(100% - 100px);
  overflow: scroll;
}

.message {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.messageOwnwer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.messageInfo {
  display: flex;
  color: gray;
  flex-direction: column;
  gap: 5px;
  font-size: 10px;
}

.messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageContent p {
  background-color: white;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
}
.input {
  background-color: white;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input input {
  width: 100%;
  border: none;
  outline: none;
  color: #2f2d52;
  font-size: 10px;
  padding: 10px;
  font-size: 18px;
}

.input button {
  border: none;
  padding: 10px 15px;
  color: white;
  background-color: #8da4f1;
  font-size: 12px;
  border-radius: 10px;
  cursor: pointer;
}

.input button:hover {
  background-color: #5d5b8d;
}
