.card {
  margin: 1rem;
  padding: 0.4rem;
  border-radius: 13px;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 20%);
}

.card:hover {
  margin: 1rem;
  padding: 0.4rem;
  border-radius: 13px;
  box-shadow: 0 0 10px rgb(0 0 0 / 32%), 0 4px 4px rgb(0 0 0 / 40%);
}

.card-header-blue {
  background-color: rgb(32, 222, 255);
  color: #fff;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}

.card-header-purple {
  background-color: #e1c2f6;
  color: #fff;
  border-radius: 13px 13px 0 0;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}

.card-header-orange {
  background-color: #e74a0c;
  color: #fff;
  border-radius: 13px 13px 0 0;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}

.card-header-red {
  background-color: #f34444;
  color: #fff;
  border-radius: 13px 13px 0 0;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}

.card-header-darkblue {
  background-color: #4c6ef8;
  color: #fff;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}

.card-header-green {
  background-color: #07880d;
  color: #fff;
  border-radius: 13px 13px 0 0;
  font-size: 30px;
  padding: 0.4rem;
  margin: 0.1rem;
}
