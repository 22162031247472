.titulo {
  color: #2f54a5;
}

.nav-bar {
  position: relative;
  color: rgb(248, 254, 255);
  background-color: #003599;
  border: 0;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 20%);
  font-family: "Alegreya Sans", sans-serif;
  min-height: 48px;
  padding: 1rem;
  justify-content: space-between;
}

.nav-header {
  padding: 2px;
  display: inline-block;
  vertical-align: top;
}

.nav-logo {
  color: #fff;
  padding: 0;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.02em;
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.nav-buttons {
  margin-left: 30px;
  font-size: 10px;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.mr-auto {
  margin-right: auto !important;
}

.nav-user {
  text-align: right;
  font-size: 14px;
  float: right;
  margin-top: 0.6rem;
  padding-right: 1rem;
}

.nav-icon {
  text-align: right;
  font-size: 14px;
  float: right;
  padding-right: 0.1rem;
  border-radius: 50px;
  margin-right: 14px;
  overflow: hidden;
  width: 35px;
  height: 35px;
}

.nav-pills {
  background-color: #03023f;
  border-radius: 0.25rem;
}

.nav-item {
  color: #fff;
}

.nav-item::before {
  transform: scalex(0);
  transform-origin: bottom right;
}

.nav-item:hover:before {
  transform: scalex(1);
  transform-origin: bottom left;
}

.nav-item::before {
  content: " ";
  display: block;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 80%);
  z-index: -1;
  transition: transform 0.3s ease;
}
