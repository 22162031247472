#sidebar {
  position: fixed;
  width: 200px;
  height: 100%;
  background: #636772;
  left: -160px;
  transition: all 150ms linear;
  z-index: 10;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%), 0 4px 4px rgb(0 0 0 / 20%);
}

#sidebar.active {
  left: 0px;
  background-color: #636772;
}

#sidebar.active ul li {
  left: 0px;
  color: rgb(236, 236, 236);
  border-bottom: 1px solid #c8ccd6;
  padding: 1rem;
  font-size: 15px;
}

#sidebar ul li {
  color: rgba(0, 0, 0, 0.9);
  list-style: none;
  border-bottom: 1px solid #636772;
  text-align: center;
  overflow: auto;
  font-size: 0px;
}

.logo {
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.sidebar-btn {
  color: #fff;
  background-color: #636772;
  padding: 0px;
  margin: 0px;
}

.sidebar-btn2 {
  color: #fff;
  background-color: #636772;
}

.sidebar-btn3 {
  color: #fff;
  background-color: #636772;
  padding: 1rem;
  margin: 0rem;
}

.sidebar-btn:hover {
  color: #fff;
  background-color: #ff4c0f;
}

.sidebar-btn2:hover {
  color: #fff;
  background-color: #129c06;
}
